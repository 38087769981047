import { Container, Link, Text } from '@zigurous/forge-react';
import React from 'react';
import { Page } from '../components';

const metadata = {
  url: 'https://zigurous.com/privacy-policy',
  title: 'Zigurous • Privacy Policy',
};

interface PrivacyPolicyProps {
  location: Location;
}

export default function PrivacyPolicy({ location }: PrivacyPolicyProps) {
  return (
    <Page className="privacy-policy" location={location} metadata={metadata}>
      <Container className="my-xxxl" fluid>
        <Text as="h1" marginBottom="sm">
          Privacy Policy
        </Text>
        <Text className="ml-xxxs" type="eyebrow">
          Last updated January 1st, 2021
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          1. Policy
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Your privacy is important to us. It is Zigurous&apos;s policy to
          respect your privacy regarding any information we may collect while
          operating our website. Accordingly, we have developed this privacy
          policy in order for you to understand how we collect, use,
          communicate, disclose and otherwise make use of personal information.
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          This privacy policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that is
          collected or shared. This policy is not applicable to any information
          collected offline or via channels other than this website. If you have
          additional questions or require more information about our privacy
          policy, do not hesitate to contact us.
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          2. Consent
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          By using our website, you hereby consent to our privacy policy and
          agree to its terms.
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          3. Information we collect
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          4. How we use your information
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          We use the information we collect in various ways, including to:
        </Text>
        <ul className="list-bullet list-indent text-subtle">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        <br />
        <Text as="h2" marginBottom="md">
          5. Log Files
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Zigurous follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services&apos; analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users&apos; movement on the website, and gathering
          demographic information.
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          6. Cookies and Web Beacons
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Like any other website, Zigurous uses &quot;cookies&quot;. These
          cookies are used to store information including visitors&apos;
          preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users&apos;
          experience by customizing our web page content based on visitors&apos;
          browser type and/or other information.
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          For more general information on cookies, please read{' '}
          <Link external href="https://www.cookieconsent.com/what-are-cookies/">
            &quot;What Are Cookies&quot; from Cookie Consent
          </Link>
          .
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          7. Third Party Privacy Policies
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Zigurous&apos;s privacy policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{' '}
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers&apos;
          respective websites.
        </Text>
        <br />
        <Text as="h2" marginBottom="md">
          8. Children&apos;s Information
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </Text>
        <Text className="text-subtle" marginBottom="lg">
          Zigurous does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </Text>
      </Container>
    </Page>
  );
}
